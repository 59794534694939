import { render, staticRenderFns } from "./scratch.vue?vue&type=template&id=66414341&scoped=true&"
import script from "./scratch.vue?vue&type=script&lang=js&"
export * from "./scratch.vue?vue&type=script&lang=js&"
import style0 from "./scratch.vue?vue&type=style&index=0&id=66414341&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66414341",
  null
  
)

export default component.exports