<template>
  <div class="page-rooter-fff" :style="{backgroundPositionY: barHeight + 'px'}">
    <headBar ref="header" title="反馈赢好礼" @setBarHeitht="setBarHeitht" left-arrow @click-left="newAppBack" />
    <div class="popgame">
      <div class="popup-box col-start-center">
        <img src="https://img.chaolu.com.cn/MINI/feedback/h5/title.png" alt="" class="title" />
        <div class="canvas-box col-between-center" ref="canvasBox">

          <div class="canvas-bg">
            <img v-if="!feedbackId" src="https://img.chaolu.com.cn/MINI/feedback/h5/tuceng-no.png" alt="" class="award-info">
            <div class="award-info" v-show="awardInfo.rewardImg">

              <div v-if="drawType === 3" class="f1 ellipsis">很遗憾，未中奖！</div>
              <div v-else class="f1 ellipsis">恭喜您抽中{{ awardInfo.rewardName }}</div>

              <img class="f3" alt :src="awardInfo.rewardImg" v-if="awardInfo.rewardImg" />
            </div>
            <canvas ref="canvas" />
          </div>

          <div class="button-box col-center-center">

            <div v-if="drawType === 0" class="button">
              <div class="close-game f28 row-center-center">
                你有1次刮奖机会
              </div>
            </div>

            <div v-if="!feedbackId" class="button col-start-center">
              <div class="close-game f28 row-center-center">
                暂无刮奖机会
              </div>
              <p class="close-tip f22">每日参与反馈，赢100鹿分和更多奖品！</p>
            </div>

            <div v-if="[1, 2].includes(drawType)" class="button col-start-center">
              <div :class="[drawType === 1 ? 'grey' : '']" class="get-game f28 row-center-center" @click="handleGet">{{ drawType === 1 ? '已领取' : '立即领取'}}</div>
              <p class="close-tip f22">奖品当日未领取，次日失效!</p>
            </div>

            <div v-if="drawType === 3" class="button col-start-center">
              <p class="close-tip f22">下次再来试试吧!</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="prize-box col-start-center">
      <img src="https://img.chaolu.com.cn/MINI/feedback/h5/title_prize.png" alt="" class="p-title" />
      <img src="https://img.chaolu.com.cn/MINI/feedback/h5/prize.png" alt="" class="p-prize" />
    </div>
    <div class="prize-box col-start-center">
      <img src="https://img.chaolu.com.cn/MINI/feedback/h5/title_rules.png" alt="" class="p-title" />
      <p class="f26 p-text">
        1、用户可反馈门店/线上app任何问题，反馈即有机会获得超值好礼。<br />
        2、每日首次反馈可得100鹿分以及1次抽奖机会，可参与48元团课券/自助周卡抽奖，奖品需要当天立即领取，过
        期无效。<br />
        3、反馈有效意见并被采纳可直接获得48元团课券/自助 周卡（二选一）。<br />
        4、中奖后，奖品需当日领取，当日未领取次日失效。
      </p>
    </div>

    <!-- 权益卡 选择城市 -->
    <van-popup title="选择城市" v-model="equityCityShow" position="bottom" round closeable safe-area-inset-bottom>
      <div class="city-select fw6">
        <h3>选择城市</h3>
        <div @click="chooseEquityCity(index)"
          :class="['select-item', 'row-between-center', equityCityIndex === index ? 'active' : '']"
          v-for="(item, index) in citys" :key="item">
          <span>{{ item }}</span>
          <img v-show="equityCityIndex === index" src="https://img.chaolu.com.cn/ACT/double11-2022/index/choose-icon.png"
            alt="">
        </div>
        <p class="p2 f24">奖品当日未领取，次日失效</p>
        <p class="p3 f22">选择指定城市卡，选择后无法修改、退换，卡仅限所选定的城市使用。</p>
        <div class="button row-center-center" @click="confirmCityChoose">确认</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { initBack, newAppBack } from "@/lib/appMethod";
import userMixin from "@/mixin/userMixin";
import headerMixin from '@/mixin/headerMixin'
import headBar from "@/components/app/headBar";
import Scratchcard from "@/lib/scratchcard";
import wx from "weixin-js-sdk";

const scratch = require("@/assets/img/tuceng.png");

export default {
  components: {
    headBar,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      awardInfo: {}, //奖品
      feedbackId: "", // 没有抽奖机会null
      drawType: "", // 没有抽奖机会null 0-未抽奖 1已领取 2-未领取 3-未中奖

      equityCityShow: false,
      equityCityIndex: '',
      citys: ['福州市', '厦门市', '广州市'],
      barHeight: 0, //头部高度
    };
  },
  async created() {
    initBack()
    await this.$getUserId()

    await this.getData()
    if (this.drawType == 0) {
      this.openedFun()
    }

  },
  methods: {
    newAppBack,
    setBarHeitht(value) {
      this.barHeight = value;
    },
    handleGet() {
      if (this.drawType !== 2) return
      if (this.awardInfo.rewardType === 'EQUITY_CARD') {
        this.equityCityShow = true
        return
      }
      this.getConfirm()
    },
    getConfirm() {
      const ps = {
        feedbackId: this.feedbackId,
        rewardId: this.awardInfo.rewardId,
        rewardType: this.awardInfo.rewardType,
        skus: this.awardInfo.skus[this.equityCityIndex || 0],
        userId: this.userId,
      };
      this.$axios
        .post(`${this.baseURLApp}/feedback/user/award`, ps)
        .then((res) => {
          if (res.data) {
            this.$toast('领取成功！')
          } else {
            this.$toast('领取失败')
          }

          this.getData()
        });
    },
    openedFun() {
      new Scratchcard({
        canvas: this.$refs.canvas,
        coverImg: scratch,
        pixelRatio: 2,
        radius: 20,
        // coverText: '刮开赢免单 祝您好运！',
        drawCallback: () => {
          console.log("init");
        },
        doneCallback: () => {
          console.log("done");
          setTimeout(() => {
            this.getData()
          }, 300);
        },
        slideCallback: () => {
          // 开始刮奖

          // 原来刮奖逻辑  换接口就行
          this.$axios
            .post(`${this.baseURLApp}/feedback/user/feedbackDraw`, {
              token: this.token,
              userId: this.userId,
              feedbackId: this.feedbackId
            })
        },
      });
    },
    getData() {
      const ps = {
        token: this.token,
        userId: this.userId,
        hasWx: true,
      };
      return this.$axios
        .post(`${this.baseURLApp}/feedback/user/feedbackDrawType`, ps)
        .then((res) => {
          this.drawType = res.data.drawType
          this.feedbackId = res.data.feedbackId
          this.awardInfo = res.data.feedbackRewardVO || {}
          if (this.drawType === 3) {
            this.awardInfo.rewardImg = 'https://img.chaolu.com.cn/MINI/feedback/h5/prize-no.png'
          }
        });
    },
    chooseEquityCity(i) {
        this.equityCityIndex = i
    },
    confirmCityChoose() {
      if (this.equityCityIndex === '') {
        return this.$toast('请选择购买城市')
      }
      this.equityCityShow = false
      this.getConfirm()
    },
  },
};
</script>

<style scoped lang="less">
.page-rooter-fff {
  background: #ff5825 url(https://img.chaolu.com.cn/MINI/feedback/h5/bg.png) no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  box-sizing: border-box;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
}

.popgame {
  .pop-head {
    height: 130px;
    // background: linear-gradient(0deg, #FFFEFF 0%, #FFEAE9 100%);
    line-height: 130px;
    position: relative;
    text-align: center;
    color: #452c29;
    font-weight: bold;

    .pop-rule-close {
      position: absolute;
      right: 32px;
      top: 38px;
      width: 44px;
      height: 44px;
      line-height: 44px;
      border-radius: 50%;
      background: rgba(39, 40, 49, 0.2);
      font-size: 28px;
      text-align: center;
    }
  }

  .popup-box {
    .title {
      width: 498px;
    }

    .canvas-box {
      width: 574px;
      height: 614px;
      background: url(https://img.chaolu.com.cn/MINI/feedback/h5/bg-1.png);
      background-size: 100% 100%;
      position: relative;
      padding: 88px 0 64px;
      margin-bottom: 36px;

      .game-title {
        font-size: 24px;
        color: #68413c;
        text-align: center;
        line-height: 1;
        position: relative;

        &::after {
          content: "\e702";
          font: normal normal normal 14px/1 vant-icon;
          position: absolute;
          left: 50%;
          top: 100%;
          font-size: 22px;
          transform: translate(-50%, 0) rotate(90deg);
        }
      }

      .canvas-bg {
        width: 380px;
        height: 240px;
        // background: url(https://img.chaolu.com.cn/MINI/feedback/h5/tuceng-no.png);
        background-size: 100% 100%;
        text-align: center;
        position: relative;

        canvas {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          z-index: 2;
          position: relative;
        }

        .award-info {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;

          .f1 {
            color: #553b2c;
            font-size: 32px;
            font-weight: bold;
            line-height: 1;
            margin: 0 0 4px;
          }

          .f3 {
            width: 309px;
            height: 240px;
          }
        }
      }
    }

    .button-box {
      height: 150px;
      color: #fff;

      .close-game {
        width: 301px;
        height: 81px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 35px;
      }

      .get-game {
        width: 290px;
        height: 70px;
        background-image: url(https://img.chaolu.com.cn/MINI/feedback/h5/button-bg.png);
        background-size: 100% 100%;
        color: #634828;
        &.grey {
          color: #716765;
          background-image: url(https://img.chaolu.com.cn/MINI/feedback/h5/button-bg-grey.png);
        }
      }

      .close-tip {
        margin: 18px 0 0;
      }
    }
  }
}

.prize-box {
  margin: 0 0 80px;
  padding: 0 50px;

  .p-title {
    width: 187px;
    margin: 0 0 32px;
  }

  .p-prize {
    width: 650px;
  }

  .p-text {
    color: #fff;
    line-height: 40px;
  }
}

.city-select {
  width: 100%;
  padding: 32px;
  box-sizing: border-box;

  h3 {
    margin-bottom: 68px;
  }
  .p2 {
    color: #F03C18;
    margin: 48px 0 4px;
  }
  .p3 {
    color: #634828;
  }
  .select-item {
    width: 686px;
    height: 128px;
    background: #F5F5F5;
    border-radius: 8px 8px 8px 8px;
    margin: 24px 0 0;
    padding: 0 32px;
    border: 1px solid transparent;
    box-sizing: border-box;

    &.active {
      border: 1px solid #242831;
    }

    img {
      width: 48px;
    }
  }

  .button {
    width: 686px;
    height: 96px;
    background: #FFDE00;
    border-radius: 8px 8px 8px 8px;
    margin: 64px 0 0;
  }
}
</style>
